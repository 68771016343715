#Loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $dark-main-color;
    z-index: 99999999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .Lottie {
        width: 100px;
    }
    h3 {
        font-size: 2em;
    }
}
