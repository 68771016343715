/*Start Helpers */
@import "./../src/SASS/animation";
@import "./../src/SASS/colors";
@import "./../src/SASS/mixin";
@import "./../src/SASS/fonts";
@import "./../src/SASS/layout";
/*End Helpers */
/* Components */

@import "./Components/Setting/Setting";
@import "./Components/Loader/Loader";

@import "./Components/Header/header";
@import "./Components/Home/Home";
@import "./Components/Event/Event";

/* Components */

/*Start Media Query */
@import "./../src/SASS/media";
