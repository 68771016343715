* {
    box-sizing: border-box;
    font-family: $main-font;
    color: $dark-seconde-color;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}
*,
*::after,
*::before {
    transition: color 0.5s ease, background 0.5s ease, border-color 0.5s ease,
        box-shadow 0.5s ease, stroke 0.5s ease, filter 0.5s ease;
}
*::selection {
    background: $dark-seconde-color;
    color: $dark-main-color;
}
body {
    background: $dark-main-color;
    font-size: $d-font-size;
    min-height: 300vh;
    overflow-x: hidden;

    // background: url("./assets/images/png/sean-pollock.png") top no-repeat;
}
