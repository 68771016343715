.Event {
    width: 100%;
    // padding-top: 100px;
    position: relative;
    > h1 {
        position: relative;
        display: inline-block;
        padding-left: 0.5em;
        filter: opacity(0.1);
        opacity: 0;
        font-size: 10em;
        line-height: 0;
        margin-top: 0.5em;
        margin-bottom: 1em;
        font-family: sans-serif;
        font-weight: 600;
        animation-delay: 0.5s;

        &::before {
            position: absolute;
            bottom: -100%;
            left: 0;
            content: "";
            width: 80%;
            height: 100%;
            background: $dark-seconde-color;
            filter: invert(0.5);
            left: 0.5em;
            transform: skew(10deg);
            z-index: -9;
        }
    }
    .container {
        position: relative;
        [role="button"] {
            position: absolute !important;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 80% !important;
            height: 80% !important;
        }
        .Event-slideshow {
            overflow: hidden;
            max-width: 100%;
            animation-delay: 0.1s;
            .Event-slideshowSlider {
                min-width: 100%;
                display: flex;
                transition: transform 1s ease !important;
                .slideshowSlider_slide {
                    min-width: 100%;
                    padding-left: 30px;
                    display: flex;
                    column-gap: 80px;
                    align-items: center;
                    flex-wrap: wrap;
                    .slide_img {
                        position: relative;
                        margin-left: 65px;
                        opacity: 0;
                        &::before {
                            position: absolute;
                            content: "";
                            top: -0px;
                            z-index: -9;
                            left: -45px;
                            width: 5px;
                            background: $dark-border-color;
                            height: 20%;
                            z-index: -999;
                        }

                        h3 {
                            position: absolute;
                            bottom: -30px;
                            font-size: 5em;
                            line-height: 1;
                            left: -50px;
                            transform-origin: left;
                            transform: rotateZ(-90deg);
                            font-family: sans-serif;
                            font-weight: 800;
                            // background: $dark-seconde-color;
                            color: $dark-border-color;
                            padding-right: 0.5em;
                        }

                        .img {
                            border: 10px solid $dark-border-color;
                            width: 100%;
                            max-width: 550px;
                            display: flex;
                            // overflow: hidden;
                            opacity: 0;
                            animation-delay: 0.1s;
                            &:hover {
                                img {
                                    transform: scale(0.95);
                                    filter: brightness(1.5) blur(1px);
                                }
                            }
                            img {
                                transition: all 0.5s ease;
                                overflow: hidden;
                                width: 100%;
                            }
                        }
                    }
                    &:nth-child(2) {
                        .slide_img {
                            &::before {
                                height: 15%;
                            }
                        }
                    }
                    &:nth-child(3) {
                        .slide_img {
                            &::before {
                                height: 17%;
                            }
                            h3 {
                                font-size: 4.5em;
                            }
                        }
                    }
                    .slide_text-content {
                        p {
                            line-height: 1.6;
                            font-size: 1.8em;
                            color: $dark-border-color;
                            max-width: 550px;
                            animation-delay: 1s;
                            opacity: 0;
                        }
                        button {
                            border: 2px solid $dark-border-color;
                            padding: 0.9em 2.5em;
                            letter-spacing: 0.1em;
                            font-weight: 600;
                            color: $dark-border-color;
                            position: absolute;
                            bottom: 0;
                            cursor: pointer;
                            background: transparent;
                            white-space: nowrap;
                            animation-delay: 1.5s;
                            opacity: 0;
                            &:hover {
                                filter: contrast(0.5);
                            }
                        }
                    }
                }
            }
            .slideshowDots {
                min-width: 100%;
                height: 10px;
                text-align: center;
                margin-top: -1em;
                margin-bottom: 2.5em;
                padding-left: 30px;
                .slideshowDot {
                    transition: transform 0.5s ease, box-shadow 0.5s ease,
                        background 0.5s ease;
                    animation-delay: 5s;
                    display: inline-block;
                    width: 5em;
                    height: 0.3em;
                    margin: 1em;
                    background: $dark-border-color;
                    cursor: pointer;
                    &.active {
                        opacity: 0.5;
                        transform: scaleX(1) rotateX(180deg);
                        transform-origin: left;
                        background: $dark-seconde-color;
                        box-shadow: 0 5px 10px 10px $dark-seconde-color;
                    }
                }
            }
        }
    }
}
