/* Dark Theme Colors */
/* Light Theme Colors */
/* Org Theme Colors */
/* Light Theme Colors */
#darkBlue {
  /* Start Style Layout */
  /* End Style Layout */
  /* Start Style Header */
  /* End Style Header */
  /* Start Style Home */
  /* End Style Home */
  /* Start Style Event */
  /* End Style Event */
  /* Start Style Loader */
  /* Start Style Loader */
}

#darkBlue * {
  color: #f1f2f6;
}

#darkBlue *::-moz-selection {
  background: #f1f2f6;
  color: #22294e;
}

#darkBlue *::selection {
  background: #f1f2f6;
  color: #22294e;
}

#darkBlue body {
  background: #22294e;
}

#darkBlue header {
  /*TODO:Add LottieFile To Button For More Butty */
}

#darkBlue header .btn-toggle button:hover span:nth-child(2) {
  border: 1px solid #ffffff;
}

#darkBlue header .btn-toggle button span {
  background: #ffffff;
}

#darkBlue header .d-header .links,
#darkBlue header .d-header .logo {
  background: #22294e;
  -webkit-box-shadow: 0 0.5em 1em #000000a1;
          box-shadow: 0 0.5em 1em #000000a1;
}

#darkBlue header .d-header .logo::after, #darkBlue header .d-header .logo::before {
  background: #22294e;
}

#darkBlue header .d-header ul li:hover,
#darkBlue header .d-header .btn-group a:hover {
  background: #ffffff10;
}

#darkBlue header .d-header ul li a img {
  -webkit-filter: invert(0);
          filter: invert(0);
}

#darkBlue .home .container .social-media::before {
  background: #22294e;
}

#darkBlue .home .container .social-media li {
  background: #22294e;
}

#darkBlue .home .container .social-media li img {
  -webkit-filter: invert(1);
          filter: invert(1);
}

#darkBlue .home .container .social-media li:last-child {
  background: #0a0f27;
}

#darkBlue .home .container .heading h1 {
  text-shadow: 0 5px 10px #000000a1;
}

#darkBlue .home .container .heading p {
  text-shadow: 0 4px 2px #000000a1;
}

#darkBlue .home .container .box {
  border: 0.1em solid #22294e;
}

#darkBlue .home .container .box .cards .cards-box .card svg path {
  stroke: #869aff;
}

#darkBlue .home .heading h1 {
  color: #869aff;
}

#darkBlue .home .heading p {
  color: #ffffff;
}

#darkBlue .home .box {
  border-color: #22294e;
}

#darkBlue .home .box .cards h3,
#darkBlue .home .box .cards span,
#darkBlue .home .box .cards h6 {
  color: #869aff;
}

#darkBlue .home .box .cards span {
  font-weight: 400;
}

#darkBlue .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img::before {
  background: #252b47;
}

#darkBlue .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img h3 {
  color: #252b47;
}

#darkBlue .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img .img {
  border: 10px solid #252b47;
}

#darkBlue .Event .container .Event-slideshow .slide_text-content p {
  color: #f1f2f6;
}

#darkBlue .Event .container .Event-slideshow .slide_text-content button {
  border: 2px solid #f1f2f6;
  color: #f1f2f6;
}

#darkBlue .Event .container .Event-slideshow .slideshowDots .slideshowDot {
  background: #252b47;
}

#darkBlue .Event .container .Event-slideshow .slideshowDots .slideshowDot.active {
  background: #f1f2f6;
  -webkit-box-shadow: 0 5px 10px 10px #f1f2f6;
          box-shadow: 0 5px 10px 10px #f1f2f6;
}

#darkBlue #Loader {
  background: #22294e;
}

#darkBlue #Loader h3 {
  color: #f1f2f6;
}
