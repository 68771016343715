@import "./../../SASS/colors";
@import "./../../SASS/fonts";

.event-page {
    width: 100vw;
    height: 100vh;
    background: {
        image: url("./../../assets/images/png/blake-parkinson.png");
        color: $dark-event-color;
        size: cover;
        repeat: no-repeat;
        attachment: fixed;
    }
    border: {
        width: 4em;
        top: 100px;
        style: solid;
        color: $dark-event-color;
    }
    overflow: hidden;
    position: relative;

    .arrow {
        position: absolute;
        top: 50%;

        transform: translateY(-50%);
        margin: 0.5em;
        cursor: pointer;
        p {
            padding: 1em 0.5em;
            background: $dark-event-color;
            border: 0.1em solid $dark-main-color;
            color: $dark-main-color;
            line-height: 0;
            font-size: 2em;
            font-weight: 600;
        }
        &.right {
            right: 0;
        }
        &.left {
            left: 0;
        }
        &::before {
            position: absolute;
            content: "";
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0.2em;
            height: 50vh;
            background: $dark-main-color;
            z-index: -99999;
        }
    }
    .container {
        padding-top: 100px;
        overflow: hidden;
        height: 100%;
        .event-page_home {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .heading {
                color: $dark-border-color;
                > h1 {
                    font-size: 5em;
                    color: inherit;
                }
                h3 {
                    font-size: 4em;
                    justify-self: flex-start;
                    color: inherit;
                    font-weight: 400;
                }
                h1,
                h3 {
                    text-shadow: 0 0.05em 0 $dark-seconde-color;
                }
            }
            .box {
                overflow: hidden;
                position: relative;
                border: 0.05em solid $dark-border-color;
                max-width: 700px;
                .box_overlay-blur {
                    position: absolute;
                    top: 0;
                    left: 0;
                    min-width: 100%;
                    min-height: 100%;
                    background: rgba(255, 255, 255, 0.674);
                    filter: blur(50px);
                }
                .event-page_content-box {
                    position: relative;
                    z-index: 9;
                    * {
                        color: $dark-seconde-color;
                    }

                    padding: 2em;
                    max-width: 100%;
                    align-items: flex-end;
                    overflow: hidden;
                    justify-self: flex-end;
                    display: flex;
                    justify-content: space-between;

                    .text {
                        color: $dark-border-color;
                        align-self: flex-start;
                        h4 {
                            font-size: 3em;
                            font-weight: 600;
                            color: inherit;
                        }
                        h6 {
                            font-size: 2em;
                            font-weight: 400;
                            color: inherit;
                            max-width: 300px;
                        }
                    }
                    button {
                        padding: 0.3em 2em;
                        align-self: flex-end;
                        border: none;
                        background: $dark-border-color;
                        cursor: pointer;
                    }
                    .date {
                        position: relative;
                        * {
                            font-family: "LCDMono2";
                        }
                        &::after,
                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            width: 0.5em;
                            background: $dark-border-color;
                            height: 100%;
                            z-index: -9;
                        }
                        &::after {
                            right: 10%;
                        }
                        &::before {
                            left: 10%;
                        }

                        .remaining,
                        .show-date {
                            background: $dark-border-color;
                            color: $dark-seconde-color;
                            padding: 1em 1.5em;
                        }
                        .remaining {
                            padding: 0.5em 2em;
                            > p {
                                font-family: "Poppins";
                            }
                        }
                        .show-date {
                            margin-top: 1em;
                            text-align: left;
                            letter-spacing: 0.3em;
                            h1,
                            h2 {
                                line-height: 1;
                            }
                            h1 {
                                font-size: 5em;
                                font-weight: 600;
                                &:last-child {
                                    opacity: 0.6;
                                    > span {
                                        font-size: 0.5em;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@import "./../../SASS/media";
