/*Start Helpers */
@-webkit-keyframes UpDown {
  form {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}
@keyframes UpDown {
  form {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
  }
}

/* Dark Theme Colors */
/* Light Theme Colors */
/* Org Theme Colors */
/* Light Theme Colors */
/* Fonts Sizes  */
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: Poppins, sans-serif;
  color: #fff;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  -webkit-transition: color 0.5s ease, background 0.5s ease, border-color 0.5s ease, stroke 0.5s ease, -webkit-box-shadow 0.5s ease, -webkit-filter 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, border-color 0.5s ease, stroke 0.5s ease, -webkit-box-shadow 0.5s ease, -webkit-filter 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, border-color 0.5s ease, box-shadow 0.5s ease, stroke 0.5s ease, filter 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, border-color 0.5s ease, box-shadow 0.5s ease, stroke 0.5s ease, filter 0.5s ease, -webkit-box-shadow 0.5s ease, -webkit-filter 0.5s ease;
}

*::-moz-selection {
  background: #fff;
  color: #070b0e;
}

*::selection {
  background: #fff;
  color: #070b0e;
}

body {
  background: #070b0e;
  font-size: 11.5px;
  min-height: 300vh;
  overflow-x: hidden;
}

/*End Helpers */
/* Components */
#setting {
  padding: 1em;
  background-color: #070b0e;
  position: fixed;
  top: 30%;
  left: 0;
  z-index: 9999999;
  max-height: 36em;
  direction: ltr;
  left: -125px;
  -webkit-transition: left 1s ease;
  transition: left 1s ease;
  cursor: pointer;
  /* Start Style Theme Setting */
}

#setting:hover {
  left: 0;
}

#setting h4 {
  font-size: 1.5em;
  font-weight: 300;
  letter-spacing: 0.15em;
  margin-bottom: 0.3em;
}

#setting h6 {
  margin-bottom: 0.5em;
  font-size: 1.5em;
  font-weight: 300;
}

#setting span.hr {
  width: 100%;
  height: 0.1em;
  background: #fff;
  display: block;
  margin-bottom: 0.5em;
}

#setting h4,
#setting h6,
#setting span.hr {
  color: #fff;
}

#setting .op {
  padding: 0.2em;
  margin: 0.7em 0;
}

#setting button {
  cursor: pointer;
  padding: 0.1em 1em;
  color: #fff;
  background: #070b0e;
  border: 1px solid #fff;
}

#setting button:first-child {
  margin-right: 1em;
}

#setting button.active {
  background: #ffffff10;
}

#setting ul {
  list-style: none;
}

#setting ul li {
  padding: 1em;
  margin-top: 1em;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-box-shadow: 0.5px 0.5px 0px white;
          box-shadow: 0.5px 0.5px 0px white;
  font-weight: 600;
  letter-spacing: 0.3em;
}

#setting ul li:nth-child(1) {
  background: linear-gradient(45deg, #070b0e 33%, #5a6e7f 33%, 66%, #fff 66%);
  color: #fff;
}

#setting ul li:nth-child(2) {
  background: linear-gradient(45deg, #f1f3f6 33%, #ffffff33 33%, 66%, #070b0e 66%);
  color: #070b0e;
}

#setting ul li:nth-child(3) {
  background: linear-gradient(45deg, #c98126 33%, #ffffff33 33%, 66%, #000 66%);
  color: #000;
}

#setting ul li:nth-child(4) {
  background: linear-gradient(45deg, #22294e 33%, #869aff 33%, 66%, #0a0f27 66%);
  color: #f1f2f6;
}

#Loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #070b0e;
  z-index: 99999999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#Loader .Lottie {
  width: 100px;
}

#Loader h3 {
  font-size: 2em;
}

header {
  padding: 0 20px;
  width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  max-height: auto;
  /*TODO:Add LottieFile To Button For More Butty */
}

header .btn-toggle {
  display: none;
  position: absolute;
  z-index: 9999;
  top: 10px;
}

header .btn-toggle * {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

header .btn-toggle button {
  all: unset;
  width: 2em;
  height: 2em;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

header .btn-toggle button:hover {
  cursor: pointer;
}

header .btn-toggle button:hover span:nth-child(1) {
  position: relative;
  top: 50%;
  -webkit-transform: rotateZ(43deg);
          transform: rotateZ(43deg);
}

header .btn-toggle button:hover span:nth-child(2) {
  height: 100%;
  width: 100%;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 0;
}

header .btn-toggle button:hover span:nth-child(3) {
  -webkit-transform: rotateZ(-43deg) translate(-1px);
          transform: rotateZ(-43deg) translate(-1px);
  position: relative;
  top: -50%;
}

header .btn-toggle button span {
  background: #fff;
  width: 100%;
  height: 1px;
  border-radius: 4px;
}

header .d-header,
header .links,
header .btn-group,
header ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

header a {
  text-decoration: none;
}

header a span {
  font-style: italic;
  letter-spacing: 0.07em;
}

header .links,
header ul,
header .btn-group {
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

header .d-header {
  width: 100%;
  -webkit-column-gap: 5em;
          column-gap: 5em;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

header .d-header .links,
header .d-header .logo {
  background: #070b0e;
  -webkit-box-shadow: 0 0.5em 1em #000000a1;
          box-shadow: 0 0.5em 1em #000000a1;
}

header .d-header .links ul {
  direction: ltr;
  list-style: none;
}

header .d-header .links ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 0.6em;
          column-gap: 0.6em;
}

header .d-header .links ul li a img {
  max-height: 1.5em;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

header .d-header .logo {
  font-size: 3em;
  padding: 0.5em 1.5em;
  position: relative;
}

header .d-header .logo::after, header .d-header .logo::before {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  height: 0.2em;
  background: #070b0e;
  z-index: -999;
}

header .d-header .logo::after {
  right: -100%;
}

header .d-header .logo:before {
  left: -100%;
}

header .d-header ul li a,
header .d-header .btn-group a {
  padding: 1.2em;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

header .d-header ul li a:hover,
header .d-header .btn-group a:hover {
  background: #ffffff10;
}

.home {
  background: url("./assets/images/png/sean-pollock.png") bottom no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
}

.home .container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}

.home .container .social-media {
  position: absolute;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  list-style: none;
  min-height: 60%;
  z-index: 999;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

.home .container .social-media::before {
  position: absolute;
  content: "";
  width: 0.17em;
  height: 100%;
  background: #070b0e;
  z-index: -99999;
}

.home .container .social-media li {
  width: 4em;
  height: 4em;
  border: 0.2em solid #070b0e;
  padding: 0.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #5a6e7f;
  -webkit-animation-delay: 2.3s;
          animation-delay: 2.3s;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.home .container .social-media li:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.home .container .social-media li:not(:last-child) {
  margin-top: 1.5em;
}

.home .container .social-media li:first-child {
  margin-top: 6em;
}

.home .container .social-media li:first-child a img {
  width: 1.3em;
}

.home .container .social-media li:last-child {
  position: absolute;
  bottom: -25px;
  background: #ffffff33;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.home .container .social-media li:last-child:hover a img {
  -webkit-animation: UpDown 1s ease infinite alternate;
          animation: UpDown 1s ease infinite alternate;
}

.home .container .social-media li a img {
  width: 1.9em;
}

.home .container .heading h1 {
  font-size: 4em;
  text-shadow: 0 5px 10px #000000a1;
  margin-bottom: 0.5em;
  letter-spacing: 0.1em;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.home .container .heading p {
  font-size: 2em;
  max-width: 50%;
  line-height: 1.6;
  margin-bottom: 1em;
  text-shadow: 0 4px 2px #000000a1;
  -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s;
}

.home .container .box {
  width: 60%;
  border: 0.1em solid #070b0e;
  justify-self: flex-end;
  position: absolute;
  bottom: 2%;
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.home .container .box .cards {
  -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s;
  padding: 2em 3em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 2em;
          column-gap: 2em;
  position: relative;
  overflow: hidden;
}

.home .container .box .cards .overlay-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000cc;
  -webkit-filter: blur(50px);
          filter: blur(50px);
  z-index: -9;
}

.home .container .box .cards h3 {
  -ms-flex-item-align: start;
      align-self: flex-start;
  letter-spacing: 0.2em;
  font-size: 2em;
  max-width: 300px;
  line-height: 1.7;
  -webkit-animation-delay: 1.35s;
          animation-delay: 1.35s;
  font-style: italic;
}

.home .container .box .cards .cards-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 3em;
          column-gap: 3em;
  justify-self: flex-end;
}

.home .container .box .cards .cards-box .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 0.5em;
  background: #070b0e;
  padding: 1em 1.5em;
  -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s;
}

.home .container .box .cards .cards-box .card svg path {
  stroke: #fff;
}

.home .container .box .cards .cards-box .card h6 {
  font-size: 1.5em;
  letter-spacing: 0.1em;
  font-weight: 600;
  margin-bottom: 0.5em;
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}

.home .container .box .cards .cards-box .card span {
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.1em;
  font-weight: 400;
  letter-spacing: 0.08em;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.Event {
  width: 100%;
  position: relative;
}

.Event > h1 {
  position: relative;
  display: inline-block;
  padding-left: 0.5em;
  -webkit-filter: opacity(0.1);
          filter: opacity(0.1);
  opacity: 0;
  font-size: 10em;
  line-height: 0;
  margin-top: 0.5em;
  margin-bottom: 1em;
  font-family: sans-serif;
  font-weight: 600;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.Event > h1::before {
  position: absolute;
  bottom: -100%;
  left: 0;
  content: "";
  width: 80%;
  height: 100%;
  background: #fff;
  -webkit-filter: invert(0.5);
          filter: invert(0.5);
  left: 0.5em;
  -webkit-transform: skew(10deg);
          transform: skew(10deg);
  z-index: -9;
}

.Event .container {
  position: relative;
}

.Event .container [role="button"] {
  position: absolute !important;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 80% !important;
  height: 80% !important;
}

.Event .container .Event-slideshow {
  overflow: hidden;
  max-width: 100%;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.Event .container .Event-slideshow .Event-slideshowSlider {
  min-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: -webkit-transform 1s ease !important;
  transition: -webkit-transform 1s ease !important;
  transition: transform 1s ease !important;
  transition: transform 1s ease, -webkit-transform 1s ease !important;
}

.Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide {
  min-width: 100%;
  padding-left: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 80px;
          column-gap: 80px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img {
  position: relative;
  margin-left: 65px;
  opacity: 0;
}

.Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img::before {
  position: absolute;
  content: "";
  top: -0px;
  z-index: -9;
  left: -45px;
  width: 5px;
  background: #444342;
  height: 20%;
  z-index: -999;
}

.Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img h3 {
  position: absolute;
  bottom: -30px;
  font-size: 5em;
  line-height: 1;
  left: -50px;
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-transform: rotateZ(-90deg);
          transform: rotateZ(-90deg);
  font-family: sans-serif;
  font-weight: 800;
  color: #444342;
  padding-right: 0.5em;
}

.Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img .img {
  border: 10px solid #444342;
  width: 100%;
  max-width: 550px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img .img:hover img {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  -webkit-filter: brightness(1.5) blur(1px);
          filter: brightness(1.5) blur(1px);
}

.Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img .img img {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow: hidden;
  width: 100%;
}

.Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide:nth-child(2) .slide_img::before {
  height: 15%;
}

.Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide:nth-child(3) .slide_img::before {
  height: 17%;
}

.Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide:nth-child(3) .slide_img h3 {
  font-size: 4.5em;
}

.Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content p {
  line-height: 1.6;
  font-size: 1.8em;
  color: #444342;
  max-width: 550px;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  opacity: 0;
}

.Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content button {
  border: 2px solid #444342;
  padding: 0.9em 2.5em;
  letter-spacing: 0.1em;
  font-weight: 600;
  color: #444342;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  background: transparent;
  white-space: nowrap;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
  opacity: 0;
}

.Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content button:hover {
  -webkit-filter: contrast(0.5);
          filter: contrast(0.5);
}

.Event .container .Event-slideshow .slideshowDots {
  min-width: 100%;
  height: 10px;
  text-align: center;
  margin-top: -1em;
  margin-bottom: 2.5em;
  padding-left: 30px;
}

.Event .container .Event-slideshow .slideshowDots .slideshowDot {
  -webkit-transition: background 0.5s ease, -webkit-transform 0.5s ease, -webkit-box-shadow 0.5s ease;
  transition: background 0.5s ease, -webkit-transform 0.5s ease, -webkit-box-shadow 0.5s ease;
  transition: transform 0.5s ease, box-shadow 0.5s ease, background 0.5s ease;
  transition: transform 0.5s ease, box-shadow 0.5s ease, background 0.5s ease, -webkit-transform 0.5s ease, -webkit-box-shadow 0.5s ease;
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
  display: inline-block;
  width: 5em;
  height: 0.3em;
  margin: 1em;
  background: #444342;
  cursor: pointer;
}

.Event .container .Event-slideshow .slideshowDots .slideshowDot.active {
  opacity: 0.5;
  -webkit-transform: scaleX(1) rotateX(180deg);
          transform: scaleX(1) rotateX(180deg);
  -webkit-transform-origin: left;
          transform-origin: left;
  background: #fff;
  -webkit-box-shadow: 0 5px 10px 10px #fff;
          box-shadow: 0 5px 10px 10px #fff;
}

/* Components */
/*Start Media Query */
.container {
  margin: 0 auto;
  padding: 0 12px;
}

/* Bootstrap Breakpoints */
/* 
============
==== Min ==== 
============
*/
@media (min-width: 300px) {
  /* Start  Layout */
  .container {
    max-width: 100%;
  }
  body {
    font-size: 7.5px;
  }
  /* End  Layout */
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
  body {
    font-size: 6.5px;
  }
}

@media (max-width: 991px) {
  header {
    max-height: 50px;
    overflow: hidden;
    background: #070b0e;
  }
  header .btn-toggle {
    display: inline;
  }
  header .d-header {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  header .d-header .links,
  header .d-header .logo {
    background: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  header .d-header .links {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  header .d-header .links ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  header .d-header .logo {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  header .d-header .logo::after, header .d-header .logo::before {
    position: static;
  }
}

@media (min-width: 575.99px) {
  /* Start Event Style  */
  .Event > h1 {
    padding-top: 0.5em;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img {
    margin-bottom: 5em;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img h3 {
    font-size: 5em;
    bottom: -15px;
    left: -45px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content p {
    text-align: justify;
    max-width: 485px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content button {
    margin-top: 2em;
    position: static;
  }
  /* End Event Style  */
}

@media (min-width: 992px) {
  .container {
    max-width: 800px;
  }
  /* Start Event Style  */
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img {
    max-width: 350px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img h3 {
    bottom: -18px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content p {
    text-align: justify;
    max-width: 485px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content button {
    margin-top: 2em;
    position: static;
    font-size: 1.5em;
  }
  /* End Event Style  */
}

@media (min-width: 1024px) {
  body {
    font-size: 7.5px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  body {
    font-size: 7.5px;
  }
}

@media (min-width: 1300px) {
  body {
    font-size: 10.5px;
  }
  header a span {
    font-size: 1em;
  }
  /* start style event */
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img {
    max-width: 550px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img h3 {
    left: -50px;
  }
  /* end style event */
  /* start style event-page */
  /* end style event-page */
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
  body {
    font-size: 11.5px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1320px;
  }
}

/*
=============
==== Max ==== 
=============
*/
@media (max-width: 767.98px) {
  .home .container .social-media {
    min-height: 40%;
    top: 10%;
  }
  .home .container .heading p {
    max-width: 80%;
  }
  .home .container .box {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  /* Start Home Style  */
  .home {
    padding-top: 15em;
    height: auto;
    padding-bottom: 5em;
  }
  .home .container {
    row-gap: 5em;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding-bottom: 5px;
  }
  .home .container .heading {
    padding: 3em;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 0.1em solid #070b0e;
  }
  .home .container .heading p {
    font-weight: 600;
  }
  .home .container .heading h1,
  .home .container .heading p {
    text-shadow: none;
    color: #070b0e;
  }
  .home .container .box {
    position: relative;
  }
  .home .container .box .cards {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 3em;
    padding: 3em;
  }
  .home .container .box .cards .cards-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 3em;
    width: 100%;
  }
  /* End Home Style  */
  /* Start Event Style  */
  .Event > h1 {
    padding-top: 0.5em;
    padding-left: 0.2em;
    margin-bottom: 1em;
  }
  .Event > h1::before {
    left: 0.2em;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0 15px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img {
    margin-bottom: 5em;
    margin-left: 35px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img::before {
    left: -20px;
    top: 0;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img h3 {
    font-size: 3em;
    bottom: -12px;
    left: -20px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content p {
    text-align: justify;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content button {
    margin-top: 2em;
    position: static;
  }
  /* End Event Style  */
}
