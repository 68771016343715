header {
    padding: 0 20px;
    width: 100vw;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    max-height: auto;

    /*TODO:Add LottieFile To Button For More Butty */
    .btn-toggle {
        display: none;
        position: absolute;
        z-index: 9999;
        top: 10px;

        & * {
            transition: all 0.5s ease;
        }
        button {
            all: unset;
            width: 2em;
            height: 2em;
            border: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            &:hover {
                cursor: pointer;
                span {
                    &:nth-child(1) {
                        position: relative;
                        top: 50%;
                        transform: rotateZ(43deg);
                    }
                    &:nth-child(2) {
                        height: 100%;
                        width: 100%;
                        background: transparent;
                        border: 1px solid $dark-seconde-color;
                        border-radius: 0;
                    }
                    &:nth-child(3) {
                        transform: rotateZ(-43deg) translate(-1px);
                        position: relative;
                        top: -50%;
                    }
                }
            }
            span {
                background: $dark-seconde-color;
                width: 100%;
                height: 1px;
                border-radius: 4px;
            }
        }
    }
    .d-header,
    .links,
    .btn-group,
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    a {
        text-decoration: none;
        span {
            font-style: italic;
            letter-spacing: 0.07em;
        }
    }
    .links,
    ul,
    .btn-group {
        column-gap: 20px;
    }

    .d-header {
        width: 100%;
        column-gap: 5em;
        animation-delay: 0.7s;
        .links,
        .logo {
            background: $dark-main-color;
            box-shadow: 0 0.5em 1em $dark-shadow-color;
        }
        .links {
            ul {
                direction: ltr;
                list-style: none;
                li {
                    a {
                        display: flex;
                        align-items: center;
                        column-gap: 0.6em;
                        img {
                            max-height: 1.5em;
                            animation-delay: 1s;
                        }
                    }
                }
            }
        }
        .logo {
            font-size: 3em;
            padding: 0.5em 1.5em;
            position: relative;
            &::after,
            &::before {
                position: absolute;
                content: "";
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: 0.2em;
                background: $dark-main-color;
                z-index: -999;
            }
            &::after {
                right: -100%;
            }
            &:before {
                left: -100%;
            }
        }

        ul li a,
        .btn-group a {
            padding: 1.2em;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                // opacity: 0.5;
                background: $dark-hover-color;
            }
        }
    }
}
