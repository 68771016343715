/* Dark Theme Colors */
/* Light Theme Colors */
/* Org Theme Colors */
/* Light Theme Colors */
/* Fonts Sizes  */
.event-page {
  width: 100vw;
  height: 100vh;
  background-image: url("./../../assets/images/png/blake-parkinson.png");
  background-color: #5a6e7f;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-width: 4em;
  border-top: 100px;
  border-style: solid;
  border-color: #5a6e7f;
  overflow: hidden;
  position: relative;
}

.event-page .arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0.5em;
  cursor: pointer;
}

.event-page .arrow p {
  padding: 1em 0.5em;
  background: #5a6e7f;
  border: 0.1em solid #070b0e;
  color: #070b0e;
  line-height: 0;
  font-size: 2em;
  font-weight: 600;
}

.event-page .arrow.right {
  right: 0;
}

.event-page .arrow.left {
  left: 0;
}

.event-page .arrow::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 0.2em;
  height: 50vh;
  background: #070b0e;
  z-index: -99999;
}

.event-page .container {
  padding-top: 100px;
  overflow: hidden;
  height: 100%;
}

.event-page .container .event-page_home {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.event-page .container .event-page_home .heading {
  color: #444342;
}

.event-page .container .event-page_home .heading > h1 {
  font-size: 5em;
  color: inherit;
}

.event-page .container .event-page_home .heading h3 {
  font-size: 4em;
  justify-self: flex-start;
  color: inherit;
  font-weight: 400;
}

.event-page .container .event-page_home .heading h1,
.event-page .container .event-page_home .heading h3 {
  text-shadow: 0 0.05em 0 #fff;
}

.event-page .container .event-page_home .box {
  overflow: hidden;
  position: relative;
  border: 0.05em solid #444342;
  max-width: 700px;
}

.event-page .container .event-page_home .box .box_overlay-blur {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background: rgba(255, 255, 255, 0.674);
  -webkit-filter: blur(50px);
          filter: blur(50px);
}

.event-page .container .event-page_home .box .event-page_content-box {
  position: relative;
  z-index: 9;
  padding: 2em;
  max-width: 100%;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  overflow: hidden;
  justify-self: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.event-page .container .event-page_home .box .event-page_content-box * {
  color: #fff;
}

.event-page .container .event-page_home .box .event-page_content-box .text {
  color: #444342;
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.event-page .container .event-page_home .box .event-page_content-box .text h4 {
  font-size: 3em;
  font-weight: 600;
  color: inherit;
}

.event-page .container .event-page_home .box .event-page_content-box .text h6 {
  font-size: 2em;
  font-weight: 400;
  color: inherit;
  max-width: 300px;
}

.event-page .container .event-page_home .box .event-page_content-box button {
  padding: 0.3em 2em;
  -ms-flex-item-align: end;
      align-self: flex-end;
  border: none;
  background: #444342;
  cursor: pointer;
}

.event-page .container .event-page_home .box .event-page_content-box .date {
  position: relative;
}

.event-page .container .event-page_home .box .event-page_content-box .date * {
  font-family: "LCDMono2";
}

.event-page .container .event-page_home .box .event-page_content-box .date::after, .event-page .container .event-page_home .box .event-page_content-box .date::before {
  content: "";
  position: absolute;
  top: 0;
  width: 0.5em;
  background: #444342;
  height: 100%;
  z-index: -9;
}

.event-page .container .event-page_home .box .event-page_content-box .date::after {
  right: 10%;
}

.event-page .container .event-page_home .box .event-page_content-box .date::before {
  left: 10%;
}

.event-page .container .event-page_home .box .event-page_content-box .date .remaining,
.event-page .container .event-page_home .box .event-page_content-box .date .show-date {
  background: #444342;
  color: #fff;
  padding: 1em 1.5em;
}

.event-page .container .event-page_home .box .event-page_content-box .date .remaining {
  padding: 0.5em 2em;
}

.event-page .container .event-page_home .box .event-page_content-box .date .remaining > p {
  font-family: "Poppins";
}

.event-page .container .event-page_home .box .event-page_content-box .date .show-date {
  margin-top: 1em;
  text-align: left;
  letter-spacing: 0.3em;
}

.event-page .container .event-page_home .box .event-page_content-box .date .show-date h1,
.event-page .container .event-page_home .box .event-page_content-box .date .show-date h2 {
  line-height: 1;
}

.event-page .container .event-page_home .box .event-page_content-box .date .show-date h1 {
  font-size: 5em;
  font-weight: 600;
}

.event-page .container .event-page_home .box .event-page_content-box .date .show-date h1:last-child {
  opacity: 0.6;
}

.event-page .container .event-page_home .box .event-page_content-box .date .show-date h1:last-child > span {
  font-size: 0.5em;
}

.container {
  margin: 0 auto;
  padding: 0 12px;
}

/* Bootstrap Breakpoints */
/* 
============
==== Min ==== 
============
*/
@media (min-width: 300px) {
  /* Start  Layout */
  .container {
    max-width: 100%;
  }
  body {
    font-size: 7.5px;
  }
  /* End  Layout */
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
  body {
    font-size: 6.5px;
  }
}

@media (max-width: 991px) {
  header {
    max-height: 50px;
    overflow: hidden;
    background: #070b0e;
  }
  header .btn-toggle {
    display: inline;
  }
  header .d-header {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  header .d-header .links,
  header .d-header .logo {
    background: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  header .d-header .links {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  header .d-header .links ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  header .d-header .logo {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  header .d-header .logo::after, header .d-header .logo::before {
    position: static;
  }
}

@media (min-width: 575.99px) {
  /* Start Event Style  */
  .Event > h1 {
    padding-top: 0.5em;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img {
    margin-bottom: 5em;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img h3 {
    font-size: 5em;
    bottom: -15px;
    left: -45px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content p {
    text-align: justify;
    max-width: 485px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content button {
    margin-top: 2em;
    position: static;
  }
  /* End Event Style  */
}

@media (min-width: 992px) {
  .container {
    max-width: 800px;
  }
  /* Start Event Style  */
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img {
    max-width: 350px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img h3 {
    bottom: -18px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content p {
    text-align: justify;
    max-width: 485px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content button {
    margin-top: 2em;
    position: static;
    font-size: 1.5em;
  }
  /* End Event Style  */
}

@media (min-width: 1024px) {
  body {
    font-size: 7.5px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  body {
    font-size: 7.5px;
  }
}

@media (min-width: 1300px) {
  body {
    font-size: 10.5px;
  }
  header a span {
    font-size: 1em;
  }
  /* start style event */
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img {
    max-width: 550px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img h3 {
    left: -50px;
  }
  /* end style event */
  /* start style event-page */
  /* end style event-page */
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
  body {
    font-size: 11.5px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1320px;
  }
}

/*
=============
==== Max ==== 
=============
*/
@media (max-width: 767.98px) {
  .home .container .social-media {
    min-height: 40%;
    top: 10%;
  }
  .home .container .heading p {
    max-width: 80%;
  }
  .home .container .box {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  /* Start Home Style  */
  .home {
    padding-top: 15em;
    height: auto;
    padding-bottom: 5em;
  }
  .home .container {
    row-gap: 5em;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding-bottom: 5px;
  }
  .home .container .heading {
    padding: 3em;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    border: 0.1em solid #070b0e;
  }
  .home .container .heading p {
    font-weight: 600;
  }
  .home .container .heading h1,
  .home .container .heading p {
    text-shadow: none;
    color: #070b0e;
  }
  .home .container .box {
    position: relative;
  }
  .home .container .box .cards {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 3em;
    padding: 3em;
  }
  .home .container .box .cards .cards-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 3em;
    width: 100%;
  }
  /* End Home Style  */
  /* Start Event Style  */
  .Event > h1 {
    padding-top: 0.5em;
    padding-left: 0.2em;
    margin-bottom: 1em;
  }
  .Event > h1::before {
    left: 0.2em;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0 15px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img {
    margin-bottom: 5em;
    margin-left: 35px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img::before {
    left: -20px;
    top: 0;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_img h3 {
    font-size: 3em;
    bottom: -12px;
    left: -20px;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content p {
    text-align: justify;
  }
  .Event .container .Event-slideshow .Event-slideshowSlider .slideshowSlider_slide .slide_text-content button {
    margin-top: 2em;
    position: static;
  }
  /* End Event Style  */
}
